.slide-pane__overlay {
    top: 64px;
}

.slide-pane__header {
    flex-direction: row-reverse;
    flex: 0 0 95px;
}

.slide-pane__close {
   padding-right: 22px;
}